import React from "react";
import sola from "../assets/image/sola.png";

const Partner = () => {
  return (
    <section
      id="partner"
      className="max-w-[96%] sm:max-w-[90%] mx-auto mt-[51px] lg:mt-[80px] flex flex-col items-center justify-center gap-[38px] lg:gap-[60px]"
    >
      <h2>Our Payment Partner</h2>
      <img
        src={sola}
        alt="sola"
        className="w-[60%] h-[59px] lg:w-[286px] lg:h-[78px] object-contain"
      />
      <h4 className="text-center max-w-[799px] w-[90%]">
        Sola Payments to ensure fast, secure, and hassle-free transactions.
        Every camp gets a dedicated Sola account, so when parents add funds, the
        money goes directly to the camp—instantly and securely
      </h4>
    </section>
  );
};

export default Partner;
