import React from "react";
import about from "../assets/image/about.png";

const About = () => {
  return (
    <section
      id="about"
      className="max-w-[96%] sm:max-w-[90%] mx-auto mt-[46px] lg:mt-[99px] flex flex-col xl:flex-row items-center justify-center gap-[40px]"
    >
      <div className="flex flex-col gap-[25px] md:gap-[43px]">
        <h2 className="text-left">Why Choose Campos?</h2>

        <h5 className="text-left">
          Parents can instantly add funds to their camper’s account online,
          making money available for purchases right away. With dedicated
          account types for campers, staff, and cost-based needs, Campos is
          built to fit the unique operations of overnight camps. Our immersive
          dashboard provides real-time insights, ensuring camps stay on top of
          every transaction.
          <br />
          <br />
          Quick checkouts keep lines moving, and our customizable kiosk
          app—available on the App Store and Google Play—makes transactions even
          smoother. And with 24/6 customer service, help is always available
          when you need it.
        </h5>
      </div>

      <img src={about} alt="about" className="w-full h-full object-contain" />
    </section>
  );
};

export default About;
