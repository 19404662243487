import React from "react";
import CButton from "./common/CButton";
import { BiRightTopArrowCircle } from "react-icons/bi";

const Upgrade = () => {
  return (
    <section
      id="upgrade"
      className="max-w-[96%] sm:max-w-[90%] mx-auto mt-[54px] lg:mt-[102px] flex flex-col items-center justify-center gap-[40px]"
    >
      <h2 className="max-w-[648px] text-[34px] leading-[40.08px] lg:leading-[52.36px]">
        Ready to Upgrade Your Camp’s Operation
      </h2>

      <h5 className="max-w-[556px] w-[90%]">
        Experience how Campos can transform the way your camp handles payments
        and account management
      </h5>

      <CButton
        name="Request a Demo"
        className="h-[52px]"
        icon={BiRightTopArrowCircle}
        url="mailto:campos.canteen@gmail.com"
      />
    </section>
  );
};

export default Upgrade;
