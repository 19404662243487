import React, { useState } from "react";
import { systemData, systemImages } from "../../constants";
import SystemData from "./SystemData";
import Slider from "react-slick";
import CButton from "../common/CButton";

const System = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
    adaptiveHeight: true,
    initialSlide: 0,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  return (
    <section
      id="system"
      className="mt-[69px] lg:mt-[79px] w-full flex flex-col items-center justify-center"
    >
      <h2 className="max-w-[420px]">The Ultimate Camp POS System</h2>
      <div className="rounded-[30px] bg-black flex flex-col items-center lg:flex-row justify-between gap-[60px] mt-10 w-full py-[70px] pb-[40px]">
        <div className="order-1 lg:order-2 w-full h-full gap-[10px] grid grid-cols-2 lg:grid-cols-none px-[22px]">
          {systemData.map((item, index) => (
            <SystemData
              imgUrl={item.img}
              title={item.systemName}
              subtitle={item.systemDetails}
              key={index}
            />
          ))}
        </div>

        <div className="order-2 lg:order-1 flex flex-col items-center gap-[40px] w-full lg:w-1/2 relative">
          <Slider {...settings2} className="w-full">
            {systemImages.map((item, index) => (
              <div key={index} className="pr-4">
                <img
                  src={item}
                  alt={`Feature ${index + 1}`}
                  className="w-full object-contain"
                />
              </div>
            ))}
          </Slider>

          <div className="absolute bottom-[71px] left-0 right-0 flex justify-center space-x-[10px] z-10">
            {systemImages.map((_, index) => (
              <div
                key={index}
                className={`h-[7px] rounded-full transition-all duration-300 bg-white ${
                  currentSlide === index
                    ? "w-[30px] opacity-30"
                    : "w-[20px] opacity-100"
                }`}
              />
            ))}
          </div>

          <div className="px-[22px] w-full flex items-center justify-center">
            <CButton
              name="Login to POS"
              url="https://admin.camppos.app/"
              className="w-full lg:w-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default System;
