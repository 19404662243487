import React from "react";
import Logo from "./common/Logo";
import apkImg from "../assets/apk/apkimg.png";
import applepay from "../assets/apk/applepay.png";
import googlepay from "../assets/apk/googlepay.png";

const Apk = () => {
  return (
    <section
      id="testimonials"
      className="relative mt-[40px] lg:mt-[56px] w-full pt-[50px] lg:pt-[74px] pb-[27px] lg:pb-[70px] rounded-[30px] bg-secondary flex flex-col md:flex-row items-center md:items-start justify-between gap-[40px] lg:gap-[49px] px-[20px] lg:px-[70px]"
    >
      <div className="flex flex-col gap-[20px] items-center md:items-start md:w-1/2">
        <Logo className="w-[137px] h-[22px] md:w-[248px] md:h-[41px]" />
        <h2 className="text-center md:text-left max-w-[300px]">
          Kiosk App is Now Avilable
        </h2>
      </div>

      <div className="md:w-1/2">
        <img src={apkImg} alt="apk image" className="w-full h-auto" />
      </div>

      <div className="flex flex-row gap-[16.5px] md:gap-[27px] h-[30px] md:hidden">
        <img src={applepay} alt="apple pay" className="w-auto h-full" />
        <img src={googlepay} alt="google pay" className="w-auto h-full" />
      </div>

      <div className="absolute hidden bottom-10 md:flex flex-row gap-[27px] h-[48px]">
        <img src={applepay} alt="apple pay" className="w-auto h-full" />
        <img src={googlepay} alt="google pay" className="w-auto h-full" />
      </div>
    </section>
  );
};

export default Apk;
