import React from "react";

const Review = ({
  camperLogo,
  camperName,
  camperReview,
  camperStar,
  camperTitle,
  camperSubtitle,
}) => {
  return (
    <div
      className="flex flex-col justify-between gap-[15px] bg-white p-[24px] lg:p-[30px] clip-custom 
      w-[100%] h-[100%] rounded-[24px] lg:rounded-[30px]"
    >
      <div className="w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] rounded-full bg-[#F3F3F3] flex items-center justify-center">
        <img
          src={camperLogo}
          alt={camperName}
          className="object-contain h-[90%] w-[90%]"
        />
      </div>

      <div className="flex flex-col items-start gap-[10px] overflow-hidden">
        <h3 className="text-tertiary lg:text-[24px] text-[19px] font-medium">
          {camperName}
        </h3>
        <h5 className="text-left text-[14px] lg:text-[18px] leading-[19.6px] lg:leading-[25.2px] text-textColor">
          “{camperReview}”
        </h5>
      </div>

      <div className="flex flex-col items-start gap-[2px]">
        <div className="flex text-primary">
          {Array.from({ length: camperStar }, (_, index) => (
            <span key={index} className="text-[16px] lg:text-[20px]">
              &#9733;
            </span>
          ))}
        </div>
        <div>
          <h3 className="text-[16px] lg:text-[18px] leading-[22.4px] lg:leading-[25.2px] font-medium text-tertiary text-left">
            {camperTitle}
          </h3>
          <p className="text-[11px] lg:text-[14px] leading-[15.4px] lg:leading-[19.6px] text-textColor">
            {camperSubtitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Review;
