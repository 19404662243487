import React from "react";
import Slider from "react-slick";
import { ourCamps } from "../constants";

const OurCamps = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    pauseOnFocus: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <section
      id="our-camps"
      className="max-w-[96%] sm:max-w-[90%] rounded-[30px] bg-black h-full min-h-[153px] lg:min-h-[183px] mx-auto mt-[29px] lg:mt-[130px] flex flex-col items-center justify-center py-[20px]"
    >
      <h2 className="text-[20px] lg:text-[24px] leading-[25px] lg:leading-[30px] text-white">
        Our Camps
      </h2>

      <div className="w-[100%] m-auto mt-3">
        <Slider {...settings}>
          {ourCamps.map((item, index) => {
            return (
              <img
                key={index}
                src={item}
                alt={`Camp ${index + 1}`}
                className="w-full h-full max-h-[100px] object-contain grayscale hover:grayscale-0 transition-all duration-500 ease-in-out"
              />
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default OurCamps;
