import React from "react";

const SystemData = ({ imgUrl, title, subtitle }) => {
  return (
    <div className="flex flex-col items-center lg:items-start justify-start p-4 w-full">
      <img
        src={imgUrl}
        alt={title}
        className="max-w-[60px] h-[60px] object-contain"
      />

      <h3 className="mt-[15px] leading-[28px] text-white lg:text-[#F49C59] text-center lg:text-left w-[160px] lg:w-full">
        {title}
      </h3>

      <h6 className="hidden lg:block">{subtitle}</h6>
    </div>
  );
};

export default SystemData;
