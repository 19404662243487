import React from "react";
import Review from "./Review";
import Slider from "react-slick";
import { testimonialsData } from "../../constants";
import "./Testimonials.css";

const Testimonials = () => {
  const settings3 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
    adaptiveHeight: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonials"
      className="mt-[50px] lg:mt-[53px] w-full pt-[50px] lg:pt-[100px] pb-[15px] lg:pb-[54px] rounded-[30px] bg-secondary flex flex-col items-start justify-center gap-[40px] lg:gap-[49px]"
    >
      <h2 className="mx-[2%] md:mx-[10%] text-center lg:text-left max-w-[346px]">
        What our Users Are Saying.
      </h2>

      <div className="w-[90%] mx-auto lg:mx-[10%]">
        <Slider {...settings3} className="top-review-slider">
          {testimonialsData.map((item, index) => {
            return (
              <div key={index} className="flex justify-center">
                <Review
                  camperLogo={item.camperLogo}
                  camperName={item.camperName}
                  camperReview={item.camperReview}
                  camperStar={item.camperStar}
                  camperTitle={item.camperTitle}
                  camperSubtitle={item.camperSubtitle}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
