import React from "react";
import campos from "../../assets/logo/campos.png";

const Logo = ({ className, img }) => {
  return (
    <img
      src={img ? img : campos}
      alt="camp pos logo"
      className={`${className ? className : "w-[137px] h-[22px]"}`}
    />
  );
};

export default Logo;
