import React from "react";
import Layout from "../layouts/Layout";
import MainSection from "../component/MainSection";
import OurCamps from "../component/OurCamps";
import About from "../component/About";
import Features from "../component/features/Features";
import System from "../component/system/System";
import Partner from "../component/Partner";
import Upgrade from "../component/Upgrade";
import Testimonials from "../component/testimonials/Testimonials";
import Apk from "../component/Apk";

const HomePage = () => {
  return (
    <Layout>
      <MainSection />
      <OurCamps />
      <About />
      <Features />
      <System />
      <Partner />
      <Testimonials />
      <Upgrade />
      <Apk />
    </Layout>
  );
};

export default HomePage;
