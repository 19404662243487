import ourCamps1 from "../assets/ourCamps/cgiColored.png";
import ourCamps2 from "../assets/ourCamps/ykp.png";
import ourCamps3 from "../assets/ourCamps/CGIEmblem.png";
import ourCamps4 from "../assets/ourCamps/ysp.png";
import ourCamps5 from "../assets/ourCamps/bottom-logo.png";
import ourCamps6 from "../assets/ourCamps/florcgi.png";
import ourCamps7 from "../assets/ourCamps/group.png";
import auto from "../assets/features/icons/auto.png";
import bulk from "../assets/features/icons/bulk.png";
import custom from "../assets/features/icons/custom.png";
import precise from "../assets/features/icons/precise.png";
import product from "../assets/features/icons/product.png";
import reports from "../assets/features/icons/reports.png";
import addon from "../assets/features/images/addon.png";
import bulkUpload from "../assets/features/images/bulkUpload.png";
import display from "../assets/features/images/display.png";
import menu from "../assets/features/images/menu.png";
import tracking from "../assets/features/images/tracking.png";
import system1 from "../assets/system/images/system1.png";
import system2 from "../assets/system/images/system2.png";
import system3 from "../assets/system/images/system3.png";
import system4 from "../assets/system/images/system4.png";
import system5 from "../assets/system/images/system5.png";
import offline from "../assets/system/icons/offline.png";
import pre from "../assets/system/icons/pre.png";
import real from "../assets/system/icons/real.png";
import touchscreen from "../assets/system/icons/touchscreen.png";
import user from "../assets/system/icons/user.png";
import ellipse from "../assets/testimonials/ellipse.png";
import ykp from "../assets/testimonials/ykp.png";
import ysp from "../assets/testimonials/ysp.png";

export const navItems = [
  {
    name: "Home",
    path: "",
  },
  {
    name: "Features",
    path: "features",
  },
  {
    name: "Contact Us",
    path: "contact-us",
  },
];

export const ourCamps = [
  ourCamps1,
  ourCamps2,
  ourCamps3,
  ourCamps4,
  ourCamps5,
  ourCamps6,
  ourCamps7,
];

export const featuresData = [
  {
    img: auto,
    featureName: "Auto-Gen Menus",
  },
  {
    img: product,
    featureName: "Product Add-ons",
  },
  {
    img: precise,
    featureName: "Precise Tracking",
  },
  {
    img: custom,
    featureName: "Custom Kiosk",
  },
  {
    img: bulk,
    featureName: "Bulk Upload",
  },
  {
    img: reports,
    featureName: "Detailed Reports",
  },
];

export const featuresImages = [tracking, menu, addon, bulkUpload, display];

export const systemData = [
  {
    img: user,
    systemName: "User-Friendly Interface",
    systemDetails: "Designed for easy navigation and quick transactions.",
  },
  {
    img: real,
    systemName: "Real-Time Inventory Management",
    systemDetails: "Keeps track of stock levels to avoid shortages.",
  },
  {
    img: touchscreen,
    systemName: "Touchscreen Compatibility",
    systemDetails:
      "Optimized for use with touchscreen devices for faster transactions.",
  },
  {
    img: offline,
    systemName: "Offline Mode",
    systemDetails:
      "Allows transactions to continue even without an internet connection.",
  },
  {
    img: pre,
    systemName: "Pre and Post Paid Accounts",
    systemDetails:
      "Enables customers to maintain prepaid accounts with order and balance history for easy tracking and management.",
  },
];

export const systemImages = [system1, system2, system3, system4, system5];

export const testimonialsData = [
  {
    camperLogo: ysp,
    camperName: "the “Dream POS”",
    camperReview:
      "I love how CampOS simplifies everything. I can track profits, sales, and expenses in detail—knowing exactly where every dollar comes from to the last detail.",
    camperStar: 5,
    camperTitle: "Chaim Maizenberg",
    camperSubtitle: "Canteen Manager YSP ‘24",
  },
  {
    camperLogo: ellipse,
    camperName: "User-friendly",
    camperReview:
      "CampOS stands out as a user-friendly and affordable platform, offering everything found on other canteen sites, plus more—all at a significantly lower cost.",
    camperStar: 4,
    camperTitle: "Yehudah Marasow",
    camperSubtitle: "CGI Toronto ‘24",
  },
  {
    camperLogo: ykp,
    camperName: "just 30 min Setup",
    camperReview:
      "Campos made setup effortless—our POS was fully operational in just 30 minutes! With an intuitive dashboard and 24/6 customer service, managing camper accounts has never been easier. Highly recommend!",
    camperStar: 5,
    camperTitle: "Chiam Newman",
    camperSubtitle: "Canteen YKP ‘24",
  },
];

export const footerAbouts = [
  {
    name: "About Campos",
    link: "#about",
  },
  {
    name: "Why Campos",
    link: "#about",
  },
  {
    name: "Our Partners",
    link: "#partner",
  },
];

export const footerContact = [
  {
    name: "P: +1 (929) 212-0736",
    link: "tel:+1 (929) 212-0736",
  },
  {
    name: "E: campos@osfy.io",
    link: "mailto:campos@osfy.io",
  },
];
