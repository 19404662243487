import React, { useEffect, useState } from "react";
import Logo from "../component/common/Logo";
import { Drawer, ThemeProvider, createTheme } from "@mui/material";
import { navItems } from "../constants";
import { MdOutlineCall } from "react-icons/md";
import CButton from "../component/common/CButton";
import DrawerList from "./DrawerList";
import { HiOutlineBars2 } from "react-icons/hi2";

const theme = createTheme();

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState("");

  const handleScroll = (id) => {
    if (id === "contact-us") {
      window.open("https://wa.me/message/4TPK4SQ225EIK1", "_blank");
      return;
    }

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }

    if (id === "") {
      window.history.replaceState(null, "", window.location.pathname);
    } else {
      window.history.pushState(null, "", `#${id}`);
    }

    setActiveNavItem(id);
    setIsOpen(false);
  };

  useEffect(() => {
    const updateActiveNav = () => {
      const currentPath = window.location.hash.replace("#", "") || "";
      setActiveNavItem(currentPath);

      if (currentPath === "") {
        window.history.replaceState(null, "", window.location.pathname);
      }
    };

    updateActiveNav();

    window.addEventListener("hashchange", updateActiveNav);
    return () => window.removeEventListener("hashchange", updateActiveNav);
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="flex w-full justify-between items-center max-w-[100%] sm:max-w-[90%] mx-auto">
        <Logo />

        <div className="hidden lg:flex flex-row justify-between lg:max-w-[80%] xl:max-w-[65%] w-full">
          <ul className="flex justify-center space-x-[30px]">
            {navItems.map((link, index) => (
              <li
                key={index}
                className={`${
                  activeNavItem !== link.path && "hover:underline"
                } flex items-center cursor-pointer`}
                onClick={() => handleScroll(link.path)}
              >
                {link.name === "Contact Us" && (
                  <MdOutlineCall
                    className={`text-[18px] ${
                      activeNavItem === "contact-us"
                        ? "text-primary"
                        : "text-[#12141E]"
                    } mr-[3px]`}
                  />
                )}
                <span
                  style={{
                    lineHeight: "22.5px",
                    fontWeight: "500",
                    fontSize: "18px",
                    color:
                      activeNavItem === link.path
                        ? "var(--color-primary)"
                        : "#2F2F2F",
                  }}
                >
                  {link.name}
                </span>
              </li>
            ))}
          </ul>

          <div className="flex flex-row gap-5">
            <CButton
              name="Login to POS"
              color="white"
              url="https://admin.camppos.app/"
              textColor="#12141E"
              border="1px solid black"
            />
            <CButton
              name="Add Balance"
              url="https://add-balance.camppos.app/"
            />
          </div>
        </div>

        <div className="lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white px-6 font-medium text-[18px] leading-[22.5px] bg-primary rounded-[64px] h-[48px] flex items-center justify-center gap-3"
          >
            <HiOutlineBars2 className="h-[24px] w-[24px]" />
            Menu
          </button>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        className="lg:hidden"
      >
        <DrawerList activeNavItem={activeNavItem} handleScroll={handleScroll} />
      </Drawer>
    </ThemeProvider>
  );
};

export default Navbar;
