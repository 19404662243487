import React, { useState } from "react";
import CButton from "./common/CButton";
import imac from "../assets/image/imac.png";
import { FiPlayCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

const MainSection = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  return (
    <section
      id="home"
      className="mt-[20px] lg:mt-[24px] w-full p-[22px] rounded-[30px] bg-gradient-to-b from-[#E47634] to-white flex flex-col items-center justify-center"
    >
      <h1 className="max-w-[857px] mt-[50px] lg:mt-[93px]">
        The All-in-One POS System for Overnight Camps
      </h1>
      <p className="text-[18px] lg:text-[22px] leading-[25.2px] lg:leading-[69px] text-tertiary mt-[30px] lg:mt-[40px] mb-[63px] lg:mb-[51px] font-normal text-center">
        Built exclusively to meet your unique needs.
      </p>
      <CButton
        name="Add Balance to Camper"
        url="https://add-balance.camppos.app/"
        border="1.6px solid white"
      />

      <div className="relative mt-8 flex justify-center">
        <img src={imac} alt="imac image" className="max-w-full h-auto" />

        <FiPlayCircle
          className="absolute text-black text-[80px] 
          opacity-40 cursor-pointer hover:opacity-50 transition 
          top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          onClick={() => setIsVideoOpen(true)}
        />
      </div>

      {isVideoOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 px-8">
          <div className="relative w-full max-w-[800px] aspect-video max-h-[90vh]">
            <button
              className="absolute -top-6 -right-6 text-white text-3xl cursor-pointer"
              onClick={() => setIsVideoOpen(false)}
            >
              <IoClose />
            </button>

            <iframe
              className="w-full h-full rounded-lg shadow-lg"
              src="https://www.youtube.com/embed/HwFmA0PiknI"
              title="YouTube Video"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default MainSection;
