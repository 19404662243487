import React from "react";
import osfy from "../assets/logo/osfy.png";
import Logo from "../component/common/Logo";
import camppos from "../assets/logo/CAMPOS.png";
import SocialIcons from "../component/common/SocialIcons";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { footerAbouts, footerContact } from "../constants";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="mt-[31px] md:mt-[87px] w-full rounded-[30px] bg-black py-10 px-5">
      <div className="container mx-auto flex flex-col md:flex-row justify-between gap-10 md:gap-5 text-center md:text-left">
        <div className="md:order-first order-last flex flex-col items-center md:items-start">
          <h6 className="text-white text-center md:text-left">Powered by:</h6>
          <img src={osfy} alt="osfy" className="w-[150px] h-[94px]" />
        </div>

        <div className="flex flex-col items-start gap-[25px]">
          <Logo img={camppos} />
          <h6 className="max-w-[437px] text-left">
            The All-in-One POS System for Overnight Camps. Campos is the first
            POS system built specifically for overnight camps.
          </h6>

          <div className="flex gap-4">
            <SocialIcons to={"/"} icon={FaFacebookF} />
            <SocialIcons to={"/"} icon={FaXTwitter} />
            <SocialIcons to={"/"} icon={FaLinkedinIn} />
            <SocialIcons to={"/"} icon={FaInstagram} />
          </div>
        </div>

        <div className="flex flex-col gap-[30px]">
          <h3 className="text-[22px] leading-[25.94px] text-primary text-left">
            About
          </h3>
          <ul className="flex flex-col gap-[20px] text-left">
            {footerAbouts.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.link}
                  className="text-white text-[18px] leading-[25.2px]"
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col gap-[30px]">
          <h3 className="text-[22px] leading-[25.94px] text-primary text-left">
            Get In Touch
          </h3>
          <ul className="flex flex-col gap-[20px] text-left">
            {footerContact.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.link}
                  className="text-white text-[18px] leading-[25.2px]"
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <h5 className="text-white md:mt-[60px]">
        © 2025 Campos. All rights reserved.
      </h5>
    </footer>
  );
};

export default Footer;
