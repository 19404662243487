import React from "react";

const FeatureIconAndName = ({ icon, name }) => {
  return (
    <div className="flex flex-col items-center text-center gap-3 w-[120px]">
      <img src={icon} alt={name} className="w-[70px] h-[70px]" />

      <h3 className="text-center mt-2 leading-[23.58px] lg:leading-[28.29px] text-[#263238]">
        {name}
      </h3>
    </div>
  );
};

export default FeatureIconAndName;
