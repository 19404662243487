import React, { useState } from "react";
import { featuresData, featuresImages } from "../../constants";
import Slider from "react-slick";
import FeatureIconAndName from "./FeaturesIconAndName";
import CButton from "../common/CButton";

const Features = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
    adaptiveHeight: true,
    initialSlide: 0,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  return (
    <section
      id="features"
      className="mt-[77px] lg:mt-[109px] w-full px-[22px] py-[44px] lg:py-[60px] rounded-[30px] bg-secondary flex flex-col items-center justify-center"
    >
      <h2>POS Features</h2>
      <div className="flex flex-col items-center lg:flex-row justify-between gap-[60px] mt-10 w-full">
        <div className="order-1 lg:order-2 w-full h-full grid grid-cols-2 place-items-center gap-[45px]">
          {featuresData.map((item, index) => (
            <FeatureIconAndName
              icon={item.img}
              name={item.featureName}
              key={index}
            />
          ))}
        </div>

        <div className="order-2 lg:order-1 flex flex-col items-center gap-[40px] w-full lg:w-1/2 relative">
          <Slider {...settings2} className="w-full">
            {featuresImages.map((item, index) => (
              <div key={index} className="px-4">
                <img
                  src={item}
                  alt={`Feature ${index + 1}`}
                  className="w-full max-h-[596px] object-contain"
                />
              </div>
            ))}
          </Slider>

          <div className="absolute bottom-[71px] left-0 right-0 flex justify-center space-x-[10px] z-10">
            {featuresImages.map((_, index) => (
              <div
                key={index}
                className={`h-[7px] rounded-full transition-all duration-300 bg-[#12141E] ${
                  currentSlide === index
                    ? "w-[30px] opacity-30"
                    : "w-[20px] opacity-100"
                }`}
              />
            ))}
          </div>

          <CButton
            name="Login to POS"
            url="https://admin.camppos.app/"
            className="w-full lg:w-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Features;
